<template>
  <Layout>
    <div class="s3-container">
      <section class="section">
        <div class="title">
          <span>演员排期</span>
        </div>
        <div class="title-es">选择拍摄日期定演员</div>
        <div class="model-s1">
          <div :style="{maxWidth: '100%'}" 
               v-if="Object.keys(targetModal).length > 0">
            <div :style="{position: 'relative'}">
              <div class="model-s1-img">
                <div class="video-items-cover">
                  <img @click="stopClick(targetModal['portfolios'] ? targetModal['portfolios'][0].url : '')" 
                  :src="targetModal['portfolios'] ? targetModal['portfolios'][0].url : ''" alt="">
                </div>
                <div class="video-items">
                  <slider
                      ref="slider"
                      :key="sliderKey.toString()"
                      :options="childrenOptions"
                      v-show="targetModal['portfolios'].length > 1">
                    <slideritem
                        class="model-video-item"
                        v-for="(video, j) in targetModal['portfolios'].slice(1)"
                        :key="j">
                        <img
                            class="cover"
                            @mousedown="imgStart"
                            @mouseup="stopClick(!video.mime.includes('video') ? video.url : null, $event)"
                            :src="`${video.url}${video.mime.includes('video') ? '?vframe/jpg/offset/1' : ''}`" alt="">

                        <img
                            @click="$refs['myVideo'].play(video.url)"
                            @touchmove="stopClick"
                            class="play-btn"
                            v-show="video.mime.includes('video')"
                            src="../../assets/play.png" alt="">
                    </slideritem>
                  </slider>
                </div>
              </div>
              <div v-show="fullDates.includes(`${targetModal.id}_${selectDate}`)"
                   class="full-swper">该模特当天排期已满</div>
            </div>
            <div class="modal-title">当天演员</div>
            <div class="modal-btn-list">
              <button v-for="(modal, i) in modals" :key="i" 
                @click="modalChange(i)"
                :class="`modal-btn ${i === modalActive && 'active'}`">
                {{modal.name}}
              </button>
            </div>
          </div>
          <div v-else class="no-data">无相关数据</div>
          <div class="calendar-wrap">
            <calendar :first-day-of-week="1" width="540px">
              <template slot="dateCell" slot-scope="{date}">
                <div
                    :class="`date-slot
                    ${!date.weekDay || +date.weekDay === 6 ? 'week' : ''}
                    ${date.isToday ? 'today' : ''}
                    ${selectDate === initDate(date.date) ? 'active-select' : ''}
                    ${!allowDate.includes(initDate(date.date)) ? 'not-select' : ''}
                    `"
                    @click="changeDate(date)">
                  <div class="date">{{initDate(date.date, true)}}</div>
                  <div
                      :class="`lunar ${date.festival || date.term ? 'is-day' : ''}`">
                    {{date.festival || date.term || date.lunar}}
                  </div>
                </div>
              </template>
            </calendar>
            <div class="date-msg"><span>点击日期查看演员档期</span></div>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="title">
          <span>预约演员</span>
        </div>
        <div class="title-es">选择演员预约拍摄</div>
        <div class="model-card-list">
          <div @click="$refs.work.open(model['portfolios'])"
               class="model-card-list-item"
               v-for="(model, i) in advanceModels" :key="i">
            <img :src="model['portfolios'][0].url" alt="">
          </div>
        </div>
      </section>
      <section class="section">
        <div class="title">
          <span>更多演员资料联系我们</span>
        </div>
        <div class="tips">温馨提醒:演员资料除了含本司LOGO水印的作品，其余均来自演员提供，不代表本司出品。若涉及侵权，请及时通知我们，我们将第一时间内删除。</div>
      </section>
      <works ref="work"  @touchmove.prevent.stop />
      <my-video ref="myVideo"  @touchmove.prevent.stop />
      <img-wrap ref="myImg" />
    </div>
  </Layout>
</template>

<script>
import Calendar from 'vue-lunar-calendar-pro';
import { getModels, getModels2 } from "@/api";
import { dateFormat } from "@/utils";
import Works from './components/Works';
import { slider, slideritem} from 'vue-concise-slider';
import ImgWrap from './components/ImgWrap'

export default {
  name: "index",
  components: {
    Calendar,
    Works,
    slider,
    slideritem,
    ImgWrap
  },
  activated() {
    this.getAllModel();
  },
  mounted() {
    this.getAdvanceModels();
  },
  data () {
    return {
      modals: [],
      targetModal: {},
      modalActive: 0,
      selectDate: '',
      modelWorks: [],
      modelCards: [],
      allowDate: [],
      options: {
        currentPage: 0,
      },
      childrenOptions: {
        currentPage: 0,
        slidesToScroll: 2,
        pagination: false
      },
      sliderKey: 0,
      currentX: 0,
      advanceModels: [],
      fullDates: []
    }
  },
  methods: {
    async changeDate (date) {
      const newDate = date ? dateFormat(+date.date, 'Y-M-D') : undefined;
      if (!this.$data.allowDate.includes(newDate)) return;
      this.selectDate = newDate;
      this.$data.modals = this.$data.modelCards
      .filter(item => Object.values(item).includes(newDate));
      this.$data.sliderKey += 1;
      this.$data.targetModal = this.$data.modals.length ? this.$data.modals[0] : {};
      this.$data.modalActive = 0;
    },

    async getAllModel() {
      const { data } = await getModels();
      let allowDates = [];
      this.$data.modelWorks = this.modelCards = data;
      data.forEach(item => {
        ['', '2', '3', '4', '5'].forEach((key) => {
          allowDates.push(item[`slot${key}`]);
          if (item[`slot${key}_enable`] === false && item[`slot${key}`]) {
            this.$data.fullDates.push(`${item.id}_${item[`slot${key}`]}`);
          }
        })
      })
      const dates = allowDates.filter(item => !!item);
      this.$data.allowDate = dates.length > 0 ? 
        [...new Set(dates)].filter((date) => new Date(dateFormat(+new Date(), 'Y-M-D')) - new Date(date) <= 0) : [];
      this.$data.selectDate = '';
      this.getLatelyDate(this.$data.allowDate);
    },

    getLatelyDate (dates = []) {
      if (dates.length < 1) return;
      const date = dates
        .map(date => ({
          date: date,
          num: Math.abs(new Date(date) - +new Date())
        }))
        .sort((a, b) => a.num - b.num > 0 ? 1 : -1)[0].date;
      const modals = [];
      this.$data.modelWorks.forEach((modal) => {
        const keys = Object.keys(modal);
        keys.forEach(key => {
          if (key.includes('slot') && modal[key] === date) {
            modals.push(modal);
          }
        })
      })
      this.$data.selectDate = date;
      this.$data.modals = modals;
      this.$data.targetModal = modals.length ? modals[0] : {};
    },

    initDate (date, isSlice = false) {
      const newDate = dateFormat(+new Date(date),  'Y-M-D');
      return isSlice ? +newDate.slice(-2) : newDate;

    },

    showImg (url) {
      if (!url) return;
      this.$refs['myImg'].open(url);
    },

    stopClick (url, event) {
      if (event && Math.abs(this.$data.currentX - event.clientX) > 100) return;
      this.showImg(url);
    },

    imgStart(event) {
      this.$data.currentX = event.clientX;
    },

    modalChange(index) {
      this.$data.modalActive = index;
      this.$data.targetModal = this.$data.modals[index];
      this.$refs.slider.$emit('slideTo', 0);
    },

    async getAdvanceModels () {
      const { data } = await getModels2();
      const haveOrder = data
      .filter(item => !!item.order)
      .sort((a, b) => a.order > b.order ? 1 : -1);
      const notOrder = data.filter(item => !item.order);
      this.$data.advanceModels = haveOrder.concat(...notOrder);
    }
  }
}
</script>

<style scoped>
@import "~vue-lunar-calendar-pro/dist/calendar.css";
@import "./model.css";
@import "./media.css";
</style>