<template>
  <div @click="visible = false"
      v-show="visible"
      class="works-list">
    <div @click.stop="() => false" class="works-list-center">
      <div class="works-list-title">
        <h3 >演员作品</h3>
        <svg @click="visible = false"
             t="1617268399295" class="icon close" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1331" width="200" height="200"><path d="M720.32 768a45.76 45.76 0 0 1-33.408-14.336l-416.64-416.576c-19.072-19.072-19.072-49.28 0-66.752 19.136-19.136 49.28-19.136 66.816 0l416.64 414.976c19.072 19.072 19.072 49.28 0 66.816a44.288 44.288 0 0 1-33.408 15.872z" fill="#172B4D" p-id="1332"></path><path d="M303.68 768a45.76 45.76 0 0 1-33.344-14.336c-19.136-19.072-19.136-49.28 0-66.752l414.976-416.64c19.072-19.072 49.28-19.072 66.816 0 19.072 19.136 19.072 49.28 0 66.816l-415.04 416.64a45.76 45.76 0 0 1-33.408 14.272z" fill="#172B4D" p-id="1333"></path></svg>
      </div>

      <div class="works-list-content-scr">
        <div class="works-list-content">
          <div :class="`video-list-item ${ i === 0 && video.width > video.height? 'model-cover' : ''}`"
               v-for="(video, i) in works"
               :key="i"
          >
            <img
                class="cover"
                @click="showImg(!video.mime.includes('video') ? video.url : null)"
                :src="`${video.url}${video.mime.includes('video') ? '?vframe/jpg/offset/1' : ''}`" alt="">
            <img
                @click="$refs['myVideo'].play(video.url)"
                class="play-btn"
                v-if="video.mime.includes('video')"
                src="../../../../assets/play.png" alt="">
          </div>
        </div>
      </div>
      <MyVideo ref="myVideo" />
      <img-wrap ref="myImg" />
    </div>
  </div>
</template>

<script>
import ImgWrap from '../ImgWrap'
export default {
  name: "index",
  components: {
    ImgWrap
  },
  data () {
    return {
      visible: false,
      works: []
    }
  },
  methods: {
    open (data = []) {
      this.$data.visible = true;
      this.$data.works = data;
    },
    showImg (url) {
      if (!url) return;
      this.$refs['myImg'].open(url);
    }
  }
}
</script>

<style scoped>
@import "../../../Home/home.css";
@import "./works.css";
</style>